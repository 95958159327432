<template>
  <div class="wrapper">
        <swiper ref="mySwiper" :options="swiperOption" class="swiper-container" >
          <!-- slides -->
          <swiper-slide class="swiper-item" v-for="item of banners" :key="item.id">
              <img class="swiper-img" :src="item.image" :alt="item.title" />
          </swiper-slide>
          <!-- Optional controls ,显示小点-->
          <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
 
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props:{
    banners:{
      type:Array,
      defalut:() => [],
    }
  },
  data() {
      return {
        swiperOption: {
          loop: true,
          pagination: {el:'.swiper-pagination',type: "bullets"},
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false
          }
        },
        
        //三张轮播，使用变量循环
        swiperList:[
            
        ]
      }
    },
    computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper
      }
    },
    mounted() {
      console.log('Current Swiper instance object', this.swiper)
      // this.swiper.slideTo(3, 1000, false)
    }
}
</script>
<style lang="scss">
.wrapper{
  width: 100%;
  height: 450px;
  position: relative;

  .swiper-container{
    width:1920px;height:450px;position:absolute;left:50%;margin-left:-980px;top:0;z-index:11
  }
  .swiper-img{
    // width: 100%;
    height: 450px;
  }
}
.swiper-pagination {
          height: .23rem;
          font-family: PingFangSC-Regular;
          font-size: .156rem;
          color: #fff;
          letter-spacing: 0;
          line-height: .12rem;
          bottom: 0;
      }
      .swiper-pagination-bullet {
        background: #fff;
        opacity: 0.8;
        height: 5px;
        width: 62px;
        border-radius: 12px;
        transition: all 0.2s; //可设置缓慢变化
      }
      .swiper-pagination-bullet-active {
          background-color:#000;
        }
        .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
            bottom: 25px !important;
        }
</style>
