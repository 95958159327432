<template>
    <div class="component-page">
        <div class="mod-header">
            <img src="../../assets/header-img.png">
            <div class="txt1 txt">
                <span :style="{color:selected===1?'#2E3693':'#666666'}" style="display:inline-block;margin-right:50px" @click="checked(1)">优秀企业基地</span>
                <span :style="{color:selected===2?'#2E3693':'#666666'}" @click="checked(2)">优秀院校基地</span>
            </div>
            <!-- <div class="more" @click="go">更多>></div> -->
        </div>
        <div class="page-main flex-block">
           <div v-for="(item, index) in list" :key="index" class="item-block">
               <div class="tx1" :title="item.name">{{item.name}}</div>
               <div class="tx2" :title="item.subName">{{item.attribute_one}}</div>
               <div class="tx3">{{item.attribute_two}}</div>
           </div>
           
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            selected:1,
            list:[
            ]
        }
    },
    mounted() {
        this.getArr();
    },
    methods: {
        checked(val){
            this.selected = val;
            this.getArr();
        },
        getArr(){
            this.$http.get('/api/index/getExcellentList',{type:this.selected===1?0:1,}).then(res => {
                this.list = res||[];
                this.$forceUpdate();
            })
        },
        go(){
            // if(this.selected === 2){
            //     this.$router.push('/partnerYx');
            // }else{
            //     this.$router.push('/partnerQy');
            // }
        }
    },
}
</script>
<style lang="scss">
.component-page{
    width:100%;
    
}
.mod-header{
    width: 100%;
    height: 101px;
    position: relative;
        img{
            width: 1920px;
            height: 101px;
            position: absolute;
            left: 50%;
            margin-left: -960px;
        }
        .more{
            position: absolute;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            top:20px;
            right: 100px;
            cursor: pointer;
        }
        .txt1{
            width:100%;
            text-align: center;
            color:#3B4857;
            font-size: 24px;
            font-weight: 700;
            position: absolute;
            top:25px;
        }
        .txt{
            top:40px !important;
            span{
                cursor: pointer;
            }
        }
    }


    .flex-block{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px !important;
        .item-block{
            width: calc(33.33% - 15px);
            margin-right: 10px;
            position: relative;
            margin-top:30px;
            height:47px;
            div{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .tx1{
                width:100%;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #666666;
            }
            .tx2{
                width:60%;
                position: absolute;
                bottom: 0px;
                left:0px;
                font-size:14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #666666;
            }
            .tx3{
                width:35%;
                position: absolute;
                bottom: 0px;
                right:0px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FF9F23;
            }
        }
            
    }
    .page-main{
        width: 1200px;
        margin: 0 auto;
        .title-img{
            width:100%;
            margin: 15px 0;
        }
        
    }
</style>
