<template>
    <div class="home-page">
        <Header/>
        <Swipe :banners="bannerList"/>
        <div class="mod-header">
            <img src="../assets/header-img.png">
            <div class="txt1">财经实训教学平台</div>
            <div class="txt2">10K+企业同步使用，持证考生终身账号使用权</div>
        </div>

        <div class="main">
            <div v-for="(item, index) in daohangConfig.cjsx_jypt" :key="index" @click="gourl(item.link)" class="single-block">
                <img :src="item.image">
                <div class="row1">{{item.name}}</div>
            </div>
            <!-- <div class="single-block">
                <img style="margin-top: 6px;" src="../assets/icon2.png">
                <div class="row1">大数据财务分析</div>
            </div>
            <div class="single-block">
                <img style="margin-top: 8px;" src="../assets/icon3.png">
                <div class="row1">大数据财务决策实训</div>
            </div>
            <div class="single-block">
                <img style="margin-top: 4px;" src="../assets/icon4.png">
                <div class="row1">自定义实训开发</div>
            </div> -->
        </div>

        <div class="flex content">
            <NewsList title="集团动态" :list="newsList1"/>
            <NewsList title="通知公告" :list="newsList2"/>
        </div>
        <MiddleHeader @backData="backData"/>
        <div class="mod-header">
            <img src="../assets/header-img.png">
            <div class="txt1 txt">全国财务数智化人才培养工程（NFTP）</div>
        </div>
        <div class="page-main">
            <img class="title-img" :src="globalConfig.nftp_procedure.image">
            <div class="nftp">
                <div class="left">
                    <div class="remark-list">
                        <div class="item">
                            <span>企业库</span><br>
                            <span class="span2">{{ntfpData.enterprise_library}}</span>
                        </div>
                        <div class="item">
                            <span>院校库</span><br>
                            <span class="span2">{{ntfpData.school_total}}</span>
                        </div>
                        <div class="item">
                            <span>人才库</span><br>
                            <span class="span2">{{ntfpData.personnel_total}}</span>
                        </div>
                    </div>
                    <div class="desc-info" v-html="globalConfig.nftp_intro.content">
                        <!-- <p>全国财务数智化人才培养工程（简称：NFTP），把行业企业的财经类
岗位要求、工艺流程等制作成产业课程，根据课程配发对应的职业技能证书，
并把课程引入院校，使学生在校期间，能够掌握岗位要求，清晰职业规划，
实现精准就业。</p>
                        <p>NFTP工程得到了各行业名企的一致认可，其中，首冠教育开发的国家
教育审批机构“教育部”1+X大数据财务分析项目得到了近百家知名企业认
可，并接受学生实习就业，对取得X证书的学生优先录用并给予岗位补贴，
一定程度上促进学生实习就业机会。</p> -->
                    </div>
                </div>
                <img class="right" :src="globalConfig.nftp_map.image">
            </div>
            <div class="btn-list">
                <div class="btn" @click="go('/partnerYx')">合作院校</div>
                <div class="btn" @click="go('/partnerQy')">合作企业</div>
                <div class="btn" @click="go('/studentResources')">注册简历</div>
                <div class="btn" @click="go('/enterpriseResources')">发布招聘</div>
                <div class="btn" style="background: linear-gradient(-47deg, rgba(255, 116, 62, 0.94), rgba(255, 203, 83, 0.94));" @click="go('/demandRegistration')">需求登记</div>
            </div>
        </div>

        <Qiyeyuanxiao/>

        <div class="mod-header">
            <img src="../assets/header-img.png">
            <div class="txt1 txt">产教融合校企合作整体解决方案</div>
        </div>
        <div class="page-main flex-block">
           <div v-for="(item, index) in jiejuefanganArr.slice(0,4)" :key="index" class="item-block">
               <span>{{item.name}}</span>
               <div @click="$router.push('/planDetail?id='+item.id)">查看</div>
           </div>
        </div>

        <div class="mod-header">
            <img src="../assets/header-img.png">
            <div class="txt1 txt">校企合作项目落地现场</div>
        </div>
        <div class="page-main flex-block" style="width:calc(100% - 34px);margin-left:17px">
            <img v-for="(item, index) in luodixianchangArr.slice(0,6)" :key="index" :style="{marginRight:index===5?'0px':'10px'}" class="item-img" :src="item.image">
        </div>
        <!-- <div class="content">
            <Caijingshixun/>
            <Table/>
            <Yuanxiaoziyuan/>
            <Xiaoqihezuo/>
            <Zhannuehezuo/>
        </div> -->
        
        <Footer/>
    </div>
</template>
<script>
import { mapState } from 'vuex';
// import Xiaoqihezuo from './components/xiaoqihezuo';
// import Zhannuehezuo from './components/zhannuehezuo';
// import Yuanxiaoziyuan from './components/yuanxiaoziyuan';
import Swipe from './components/swipe';
// import Table from './components/table';
import NewsList from './components/newsList';
import MiddleHeader from './components/middleHeader';
// import Caijingshixun from './components/caijingshixun';
import Qiyeyuanxiao from './components/qiyeyuanxiao.vue';
export default {
    computed: mapState([
        'globalConfig',
        'daohangConfig',
        'userInfo'
    ]),
    components:{
        NewsList,
        MiddleHeader,
        // Caijingshixun,
        Swipe,
        // Table,
        // Yuanxiaoziyuan,
        // Xiaoqihezuo,
        // Zhannuehezuo,
        Qiyeyuanxiao
    },
    data() {
        return {
            bannerList:[],
            newsList1:[],
            newsList2:[],
            jiejuefanganArr:[],
            luodixianchangArr:[],
            ntfpData:{},
        }
    },
    beforeMount() {
        // get banner img
        this.$http.get('/api/index/getBannerList').then(res => {
            res.length&&(this.bannerList = res);
        })
        // get news
        this.$http.get('/api/index/getNewsList',{is_index:1}).then(res => {
            console.log('news', res);
            this.newsList1 = res[0];
            this.newsList2 = res[1];
        })

        this.$http.get('/api/index/getSchoolEntParnterList',{type:0}).then(res => {
            res.length&&(this.jiejuefanganArr = res);
        })

        this.$http.get('/api/index/getSchoolEntParnterList',{type:1}).then(res => {
            res.length&&(this.luodixianchangArr = res);
        })
    },
    methods: {
        backData(res){
            console.log(res);
            this.ntfpData = res;
        },
        go(path){
            this.$router.push(path);
        },
        gourl(url){
            if(Object.hasOwnProperty.call(this.userInfo,'role_id')&&Number(this.userInfo.role_id)!==1){
                window.open(url);
            }else if(!Object.hasOwnProperty.call(this.userInfo,'role_id')){
                this.$router.push('/login');
            }else{
                this.$toast('企业用户暂无权限跳转');
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.flex{
    display: flex;
    justify-content: space-between;
}
.content{
    width: 1200px;
    margin: auto;
}


.mod-header{
    width: 100%;
    height: 101px;
    position: relative;
        img{
            width: 1920px;
            height: 101px;
            position: absolute;
            left: 50%;
            margin-left: -960px;
        }
        .txt1{
            width:100%;
            text-align: center;
            color:#3B4857;
            font-size: 24px;
            font-weight: 700;
            position: absolute;
            top:25px;
        }
        .txt{
            top:40px !important;
        }
        .txt2{
            width:100%;
            text-align: center;
            color:#707172;
            font-size: 15px;
            font-weight: 500;
            position: absolute;
            bottom:15px;
        }
    }

    .flex-block{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px !important;
        .item-img{
            width: calc(16.66% - 9px);
            // height: 186px;
            margin-right: 10px;
            margin-top: 30px;
        }
        .item-block{
            width:280px;
            height: 146px;
            margin-top: 30px;
            margin-right: 20px;
            box-sizing: border-box;
            background: rgba(60, 138, 230, 0.1);
            border: 1px solid #3C8AE6;
            position: relative;
            span{
                display: inline-block;
                width:100%;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #000000;
                text-align: center;
                position: absolute;
                top: 25px;
            }
            div{
                width: 95px;
                height: 33px;
                line-height: 33px;
                text-align: center;
                border: 1px solid #3C8AE6;
                position: absolute;
                bottom:25px;
                cursor: pointer;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                left:94px;
            }
        }
    }
    .page-main{
        width: 1200px;
        margin: 0 auto;
        .title-img{
            width:100%;
            margin: 24px 0;
        }
        .nftp{
            display: flex;
            width: 1120px;
            margin: auto;
            margin-top: 30px;
            .left{
                width:62%;
                .remark-list{
                    display: flex;
                    .item{
                        width: 30%;
                        color: #3E3E3E;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        .span2{
                            font-size: 18px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #2E3693;
                            display: inline-block;
                            margin-top: 6px;
                        }
                    }
                }
                .desc-info{
                    margin-top: 62px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                    p{
                        text-indent: 2em;
                        line-height: 35px;
                    }
                }
            }
            .right{
                width:38%;
            }
        }
        .btn-list{
            width:100%;
            display: flex;
            margin-top: 30px;
            .btn{
                width: 160px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 18px;
                margin-right: 70px;
                color: #fff;
                font-weight: 600;
                cursor: pointer;
                background: linear-gradient(-6deg, rgba(63, 72, 165, 0.94), rgba(61, 117, 202, 0.94), rgba(42, 206, 243, 0.94));
            }
        }
    }




.main{
    width: 1080px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 26px;
    margin-bottom: 25px;
}
.single-block{
    width: 214px;
    height: 112px;
    cursor: pointer;
    // border: 2px solid #4251B0;
    border-radius: 31px;
    position: relative;
    img{
        margin:auto;
        margin-top:0px;
        width:80px;
        display: block;
    }
    div{
        width: 100%;
        font-size: 20px;
        text-align: center;
        color:#6d79c7;
        position: absolute;
        left:0px;
    }
    .row1{
        bottom:0px;
    }
    .row2{
        top:190px;
    }
}
</style>
