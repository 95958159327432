<template>
    <div class="middle-header">
        <div v-if="hasLoad" class="content">
            <div v-for="(item, index) in arr" :key="index" class="block">
                <div class="title">{{item.title}}</div>
                <div class="sub-title">{{item.subTitle}}</div>
                <div v-if="index!==4" class="line"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            hasLoad:false,
            arr:[
                {title:'5K+课时',subTitle:'课程资源'},
                {title:'100+名',subTitle:'专家资源'},
                {title:'400+所',subTitle:'试点院校'},
                {title:'10W+家',subTitle:'企业资源库'},
                {title:'10W+名',subTitle:'财经人才库'},
            ]
        }
    },
    mounted() {
        this.$http.get('/api/index/getRealtimeData').then(res => {
            this.arr[0].title = `${res.course_total}课时`;
            this.arr[1].title = `${res.expert_total}名`;
            this.arr[2].title = `${res.school_total}所`;
            this.arr[3].title = `${res.company_total}家`;
            this.arr[4].title = `${res.personnel_total}名`;
            this.$emit('backData',res);
            this.hasLoad = true;
            this.$forceUpdate();
        })
    },
}
</script>
<style lang="scss" scoped>
.middle-header{
    width:100%;
    height: 140px;
    background: linear-gradient(90deg, rgba(63, 72, 165, 0.94), rgba(61, 117, 202, 0.94), rgba(42, 206, 243, 0.94));
}
.content{
    width: 1060px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    .block{
        margin-top:30px;
        position: relative;
        .line{
            position: absolute;
            width:1px;
            height:46px;
            background: linear-gradient(90deg, rgba(63, 72, 165, 0.4), rgba(255, 255, 255, 0.94), rgba(42, 206, 243, 0.4));
            top:10px;
            right: -66px;
        }
    }
    .title{
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 2px;
        color: #fff;
        text-align: center;
    }
    .sub-title{
        font-size: 20px;
        font-weight: 400;
        color: #fff;
        text-align: center;
    }
}
</style>
