<template>
    <div class="single-news">
        <img :src="item.image">
        <div class="content">
            <div class="title">
                {{item.title}}
            </div>
            <div class="desc">
                {{item.content}}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        item:{
            type:Object,
            defalut:() => {},
        }
    }
}
</script>
<style lang="scss" scoped>
.single-news{
    width:560px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
img{
    width:150px;
    height:100px;
    display: block;
}
.content{
    display: inline-block;
    vertical-align: middle;
    height:100px;
    width: 397px;
    color: #666666;
    .title{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
        font-size: 15px;
        color: #333;
    }
    .desc{
        margin-top: 6px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}
</style>
