<template>
    <div class="news-list">
        <div class="title">{{title}}
            <div class="more"><a :href="title==='集团动态'?'/info?type=0':'/info?type=1'" style="color:#666666;font-size:14px;font-weight: 400;">更多>></a></div>
        </div>
        <SingleNews v-for="(item, index) in list" :key="index" :item="item" class="single" @click.native="goDetail(item)"/>
    </div>
</template>
<script>
import SingleNews from './singleNews';
export default {
    components:{
        SingleNews,
    },
    props:{
        title:{
            type:String,
            default:() => '',
        },
        list:{
            type:Array,
            default:() => [],
        }
    },
    methods: {
        goDetail(item){
            let type;
            this.title === '通知公告'?type=1:type=0;
            window.location.href = `/detail?id=${item.id}&type=${type}`;
        }
    },
}
</script>
<style lang="scss" scoped>
.news-list{
    width: 100%;
    .title{
        font-size: 22px;
        font-weight: bold;
        color: #2E3693;
        margin-bottom: 20px;
        margin-top:20px;
        position: relative;
        div{
            position: absolute;
            right: 42px;
            top: 5px;
            font-size: 16px;
        }
    }
    .single{
        margin-bottom: 52px;
    }
}
</style>
